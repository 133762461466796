import React from "react";
import { PageHeader as AntPageHeader } from "antd";
import styled from "styled-components";

const Content = styled.div`
  padding: 20px 24px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  width: 100%;
`;

export const PageContainer = (props) => {
  return <Content {...props} />;
};

export const PageHeader = styled(AntPageHeader)`
  padding: 20px 0px;
`;

export const PageContent = styled.div``;

export const TablleWrapper = styled.div`
  .thisMobile {
    .ant-table-tbody > tr > td {
      border: none !important;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      display: none;
    }
  }
`;
