import React from "react";
import styled from "styled-components";
import { PageContainer, PageContent } from "../Layout";

export default () => {
  const [height, setHeight] = React.useState(0);

  const handleResize = (e) => {
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <PageContainer>
      <PageContent>
        <LoadWrapper style={{ height: height - 150 }}>
          <div className="c-loader"></div>
          <p className="text">Түр хүлээнэ үү</p>
        </LoadWrapper>
      </PageContent>
    </PageContainer>
  );
};

const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(3);
    max-width: 100vw;
    overflow: hidden;
  }
  .c-loader {
    width: 80px;
    height: 80px;
    border: 1px solid #988ccd;
    border-top-color: #3a3361;
    display: block;
    animation: is-rotating 1s infinite;
    border-radius: 50%;
  }
  .text {
    color: white;
    font-size: 13px;
  }

  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;
