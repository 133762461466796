import { PlusOutlined, SearchOutlined, ToolFilled } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { order as orderApi, part as partApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import MyTable from "../../components/MyTable";
import { tugrug } from "../../utils";
import Quantity from "./components/Quantity";

const { Option } = Select;

export default () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const myTableRef = useRef();
  const { makers, archives, s3host } = useSelector((state) => state.general);
  const [{ markets, models, bodies, years }, setSelection] = React.useState({
    markets: [],
    models: [],
    bodies: [],
    years: [],
  });
  const { carts, orders } = useSelector((state) => state.checkout);
  const [drafts, setDrafts] = React.useState([]);
  const [position, setPosition] = React.useState({});
  const [action, setAction] = React.useState();
  const [order, setOrder] = React.useState();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
  });
  const [loading, setLoading] = React.useState();
  const [offset, setOffset] = React.useState({
    page: 1,
    limit: 10,
  });
  const [{ type, payload }, setResult] = React.useState({
    type: "PRODUCT",
    payload: [],
  });

  const onAddCart = (type, item, box) => {
    if (item.quantity <= 0)
      return message.warning("Барааны үлдэгдэл хүрэлцэхгүй байна!");

    if (type === "PURCHASE") {
      setAction(["cart", item, box, type]);
    } else {
      setAction(["cart", item, box?.box_no, type]);
    }
  };

  const columns = useHeader({
    s3host,
    history,
    carts,
    orders,
    onAddCart,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/part/edit/${item.id}`);
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction();
    setOrder(null);
  };

  const onPagination = (page) => {
    let value = {
      ...offset,
      page,
    };

    setOffset(value);
    setFilters((state) => ({
      ...state,
      offset: value,
    }));
  };

  const onChange = async (type, value) => {
    if (!value) return onClear(type);

    let change = {
      [type]: value,
    };

    switch (type) {
      case "maker_id": {
        onClear("market_id");
        break;
      }
      case "market_id": {
        onClear("body_id");
        break;
      }
      case "body_id": {
        break;
      }
      default:
    }

    let res = await partApi.query(type, value, { maker_id: filters.maker_id });

    setSelection((state) => ({ ...state, ...res }));

    setFilters((state) => ({ ...state, ...change }));
  };

  const onClear = (type) => {
    let change = {
      [type]: null,
    };

    switch (type) {
      case "maker_id": {
        change = {
          ...change,
          market_id: null,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "market_id": {
        change = {
          ...change,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "model_id": {
        change = {
          ...change,
          body_id: null,
          year: null,
        };
        break;
      }
      case "body_id": {
        change = {
          ...change,
          year: null,
        };

        onChange("model_id", filters.model_id);
        break;
      }
      case "year": {
        change = {
          ...change,
          body_id: null,
        };

        onChange("model_id", filters.model_d);
        break;
      }
      default:
    }

    setFilters((state) => ({ ...state, ...change }));
  };

  const onCheckout = async (order) => {
    let res = await orderApi.get(order.id);

    dispatch({
      type: "checkout.carts",
      payload: res.products?.map((p) => ({
        qty: p.quantity,
        product_id: p.id,
        name: p.name,
        image: p.image,
        price: p.price,
        sale_price: p.sale_price,
      })),
    });

    setOrder(res);
    setAction(["calculation", "PURCHASE"]);
  };

  const onPosition = (pos) => {
    setPosition(pos);
  };

  const loadDrafts = async () => {
    let res = await orderApi.drafts();

    setDrafts(res);
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({
          ...state,
          maker_id: null,
          market_id: null,
          model_id: null,
          query,
        }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    if (params.archive_id !== filters?.archive_id) {
      setFilters((state) => ({
        ...state,
        archive_id: params.archive_id,
      }));
    }
  }, [params]);

  const reload = React.useCallback(
    async (signal) => {
      try {
        setLoading(true);

        let res = await partApi.search(
          {
            filter: filters,
            offset,
          },
          { signal }
        );

        setResult(res);
        setLoading(false);

        if (res.type === "PART" && res.payload?.body_positions[0]) {
          let position = res.payload?.body_positions[0];

          setPosition(position);
        }
      } catch (err) {
        setResult({ type: "PRODUCT", payload: [] });
        setLoading(false);

        throw err;
      }
    },
    [filters, offset]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);
    loadDrafts();

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader
        title={
          <span>
            {archives.find((a) => a.id === params.archive_id)?.name ||
              "Борлуулалт хийх"}
          </span>
        }
        extra={[
          ...drafts.map((order, index) => (
            <CartButton key={index} onClick={() => onCheckout(order)}>
              <Button type="default" shape="circle" icon={order.quantity} />{" "}
              {tugrug(order.total_amount)}
            </CartButton>
          )),
        ]}
      />

      <PageContent>
        <Row gutter={8} style={{ marginBottom: 20 }}>
          <Col xs={24} md={24} lg={4} style={{ marginBottom: 5 }}>
            <Input
              value={query}
              placeholder="OEM эсвэл PART"
              onChange={(e) => setQuery(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        <Spin spinning={loading}>
          {type === "PART" ? (
            <PartContainer>
              <PageHeader
                title={`${payload?.maker?.name} ${payload?.model?.name}`}
                subTitle={payload?.market?.name}
              />
              <Row gutter={8}>
                <Col className="left schema" xs={24} xl={6}>
                  <label>Дугаар (body number):</label>
                  <h2>{payload?.code}</h2>
                  <label>Үйлдвэрлэсэн огноо:</label>
                  <h2>
                    {payload?.start_date}-{payload?.end_date}
                  </h2>

                  <div className="image">
                    <img src={`${s3host}/${position.image}`} width="100%" />
                  </div>
                </Col>
                <Col className="main" xs={24} xl={18}>
                  <ul className="tabs">
                    {payload?.body_positions?.map((pos, index) => {
                      return (
                        <li
                          id={pos.code}
                          key={index}
                          className={`${position?.id === pos.id && "active"}`}
                        >
                          <a className="tab" onClick={() => onPosition(pos)}>
                            <img src={`${s3host}/${pos.icon}`} alt="" />
                            {pos.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>

                  <MyTable
                    columns={columns}
                    ref={myTableRef}
                    pagination={false}
                    items={payload?.parts
                      ?.filter(
                        (part) => part?.body_position_id === position?.id
                      )
                      ?.sort(
                        (a, b) =>
                          (a?.sort_of_position ?? 0) -
                          (b?.sort_of_position ?? 0)
                      )}
                    rowKey={(record) => record.id}
                  />
                </Col>
              </Row>
            </PartContainer>
          ) : (
            <>
              <MyTable
                columns={columns}
                ref={myTableRef}
                items={payload?.rows}
                pagination={false}
                rowKey={(record) => record.id}
              />

              {payload?.count > 0 && (
                <Pagination
                  style={{ marginTop: 20 }}
                  current={offset.page}
                  total={payload?.count}
                  onChange={onPagination}
                />
              )}
            </>
          )}
        </Spin>
      </PageContent>

      <Modal
        title={action && `Хайрцаг: ${action && action[2].box_no}`}
        visible={action && action[0] === "cart"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        <Quantity
          onCancel={onCancel}
          item={action && action[1]}
          action={action}
        />
      </Modal>
    </PageContainer>
  );
};

const CartButton = styled.div`
  display: inline-block;
  padding-right: 10px;
  background-color: #e8e8e8;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
`;

const useHeader = ({ s3host, carts, onAddCart, orders }) => {
  return [
    {
      title: "On scheme",
      width: 100,
      render: (record) => {
        if (!record.part) return record.sort_of_position;

        return (
          <>
            {record.sort_of_position}{" "}
            <Avatar
              src={record?.image || `${s3host}/${record?.part?.image}`}
              size={100}
              shape="square"
            />
          </>
        );
      },
    },
    {
      title: "PART / Мэдээлэл",
      render: (record) => {
        if (!record?.part)
          return (
            <>
              <p style={{ margin: 0 }}>
                Not Available
                {record.additional && (
                  <div style={{ fontSize: 12 }}>{record.additional}</div>
                )}
              </p>
            </>
          );

        const onCar = (note) => {
          let array = note
            ?.split("on car:")[1]
            ?.split("&&")[0]
            ?.trim()
            ?.split("package:");

          let car = ((array && array[0]) || "")?.trim();
          let pcs = ((array && array[1]) || "")?.trim();

          return (
            <>
              <Tag style={{ marginBottom: 10 }} color="#f50">
                On car: {car}{" "}
              </Tag>
              <Tag style={{ marginBottom: 10 }} color="#2db7f5">
                Package: {pcs}{" "}
              </Tag>
            </>
          );
        };

        return (
          <>
            <Tag color="black">{record?.part?.code}</Tag>
            <p>
              {record?.part?.name}
              {record?.additional && (
                <div style={{ fontSize: 12 }}>{record?.additional}</div>
              )}
            </p>
            <div>
              <Space>
                {record.note && onCar(record.note)}
                <Tag
                  style={{ marginBottom: 10 }}
                  color={record?.part?.product?.quantity > 0 ? "blue" : "red"}
                >
                  Нийт үлдэгдэл: {record?.part?.product?.quantity}
                </Tag>
                {record?.qties?.map((q) => (
                  <Tag style={{ marginBottom: 10 }} color="blue">
                    {q.name}: {q.qty}
                  </Tag>
                ))}
                {record?.part?.product?.shipment_quantity > 0 && (
                  <Tag style={{ marginBottom: 10 }} color="green">
                    Татан авалт: {record?.part?.product?.shipment_quantity}
                  </Tag>
                )}
                {record?.part?.product?.produce_quantity > 0 && (
                  <Tag style={{ marginBottom: 10 }} color="lime">
                    Татан авалт: {record?.part?.product?.produce_quantity}
                  </Tag>
                )}
              </Space>
            </div>
            {(() => {
              let cartCount = carts
                ?.filter((c) => c.product_id === record?.part?.product?.id)
                ?.reduce((acc, iter) => {
                  return parseFloat(acc) + parseFloat(iter.qty);
                }, 0);

              let orderCount = orders?.find(
                (c) => c.product_id === record?.part?.product?.id
              );

              if (cartCount || orderCount) {
                return (
                  <div style={{ marginBottom: 10 }}>
                    <Space>
                      {cartCount !== 0 && (
                        <Tag color="geekblue">Сагсанд: {cartCount}</Tag>
                      )}
                      {orderCount && (
                        <Tag color="purple">
                          Захиалхаар төлөвлөсөн: {orderCount.qty}
                        </Tag>
                      )}
                    </Space>
                  </div>
                );
              }
            })()}

            {(() => {
              return (
                <>
                  {record?.boxes
                    ?.filter((b) => b.qty > 0)
                    .map((box, index) => (
                      <Button
                        type="primary"
                        key={index}
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          onAddCart("PURCHASE", record?.part?.product, box)
                        }
                        styles={{ marginBottom: 5, marginRight: 5 }}
                      >
                        <PlusOutlined /> {`Хайрцаг (${box.box_no}): ${box.qty}`}
                      </Button>
                    ))}
                </>
              );
            })()}
          </>
        );
      },
    },
    {
      title: "Үнэ",
      width: 100,
      render: (record) => {
        if (!record?.part) return "-";

        return (
          <>
            {tugrug(record?.part?.product?.price)}{" "}
            <Tag>Б.Үнэ: {tugrug(record?.part?.product?.sale_price)}</Tag>
          </>
        );
      },
    },
    {
      title: "Гарын авлага",
      width: 50,
      render: (record) => {
        if (!record?.part) return "-";

        return (
          <a href={record?.part?.manual_url} target="_blank">
            <Button className="manual" type="default" size="large">
              <ToolFilled size={44} />
            </Button>
          </a>
        );
      },
    },
  ];
};

const PartContainer = styled.div`
  position: relative;
  background: #fff;
  .manual {
    &.ant-btn {
      height: 50px;
      font-size: 22px;
    }
  }
  .schema {
    width: 100%;
    .image {
      width: 100%;
    }
  }
  .main {
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        margin-bottom: 5px;
        &.active {
          a.tab {
            background: #f3f3f3;
            color: #feca0b;
            border-bottom: 4px solid #feca0b;
          }
        }
        a.tab {
          display: flex;
          flex-direction: column;
          padding: 10px;
          cursor: pointer;
          /* background: #333; */
          border: 1px solid #ddd;
          border-bottom: 4px solid #ddd;
          color: #333;
          img {
            width: 100px;
            margin: auto;
          }
          &:hover {
            background: #f3f3f3;
            color: #feca0b;
          }
        }
      }
    }
  }
`;
