import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { kn_part as knPartApi, kn_vehicles as knVehiclesApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import { tugrug } from "../../utils";

export default () => {
  const { s3host } = useSelector((state) => state.general);

  const [query, setQuery] = React.useState("");
  const [filter, setFilter] = React.useState({
    maker: null,
    model: null,
    year: null,
    engine: null,
    makers: [],
    models: [],
    engines: [],
  });

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 1950 + 1 },
    (_, i) => ({
      value: (1950 + i).toString(),
      label: (1950 + i).toString(),
    })
  ).reverse();

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchMakers() {
      try {
        const response = await knVehiclesApi.filter(filter.year, "maker", "0");
        setFilter((prev) => ({ ...prev, makers: response }));
      } catch (error) {
        console.error("Error fetching makers:", error);
      }
    }

    if (filter.year) {
      fetchMakers();
    }
  }, [filter.year]);

  useEffect(() => {
    async function fetchModels() {
      if (filter.year && filter.maker) {
        try {
          const response = await knVehiclesApi.filter(
            filter.year,
            "model",
            filter.maker.id
          );
          setFilter((prev) => ({ ...prev, models: response }));
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      }
    }

    fetchModels();
  }, [filter.year, filter.maker]);

  useEffect(() => {
    async function fetchEngines() {
      if (filter.year && filter.model) {
        try {
          const response = await knVehiclesApi.filter(
            filter.year,
            "engine",
            filter.model.id
          );
          setFilter((prev) => ({ ...prev, engines: response }));
        } catch (error) {
          console.error("Error fetching engines:", error);
        }
      }
    }

    fetchEngines();
  }, [filter.year, filter.model]);

  const handleSearch = async () => {
    const { year, maker, model, engine, query } = filter;
    const payload = {
      filter: {
        query,
        year,
        maker: maker?.maker,
        model: model?.model,
        engine: engine?.engine,
      },
      offset: {
        page: "1",
        limit: "20",
      },
    };

    try {
      setLoading(true);
      const response = await knPartApi.search(payload);
      setFilter((prev) => ({ ...prev, results: response }));
      setLoading(false);
    } catch (error) {
      console.error("Error searching parts:", error);
    }
  };

  const handleChange = (field) => (value) => {
    if (field === "maker" || field === "model" || field === "engine") {
      const selected = filter[`${field}s`].find((item) => item.id === value);
      setFilter((prev) => ({ ...prev, [field]: selected }));
    } else {
      setFilter((prev) => ({ ...prev, [field]: value }));
    }
  };

  const columns = [
    {
      title: "№",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Зураг",
      render: (record) => {
        return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ангилал",
      render: (record) => record?.category?.name,
    },
    {
      title: "Дотоод код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үнэ",
      key: "both_price",
      render: (record) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          {tugrug(record?.price)}
          <Tag>Б.Үнэ: {tugrug(record?.sale_price)}</Tag>
        </span>
      ),
    },
    {
      title: "Үлдэгдэл",
      render: (record) => {
        return (
          <div>
            <Tag>Нийт үлдэгдэл: {record?.quantity}</Tag>
            <Tag>
              {(record?.qties || []).map((qt) => {
                return <span key={qt.id}>{`${qt.name}:${qt.qty}`}</span>;
              })}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      render: (record) => (
        <Tooltip title="Дэлгэрэнгүй">
          <Button
            icon={<EyeOutlined />}
            onClick={() => history.push(`/product/kn/${record?.knPart?.id}`)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <PageContainer>
      <PageHeader title="Барааны жагсаалт" />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ maxWidth: "250px", width: "100%" }}
            onChange={(e) => {
              setQuery(e.target.value);
              setFilter((prev) => ({ ...prev, query: e.target.value }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            prefix={<SearchOutlined />}
            allowClear
          />
          <Select
            style={{ width: 150 }}
            placeholder="Он сонгох..."
            onChange={handleChange("year")}
            options={yearOptions}
            showSearch
            optionFilterProp="label"
            allowClear
          />
          <Select
            value={filter.maker ? filter.maker.id : null}
            onChange={handleChange("maker")}
            disabled={!filter.makers.length}
            placeholder="Үйлдвэрлэгч сонгоно уу"
            showSearch
            optionFilterProp="label"
            options={filter.makers.map((maker) => ({
              value: maker.id,
              label: maker.maker,
            }))}
            dropdownMatchSelectWidth={false}
            allowClear
          />
          <Select
            value={filter.model ? filter.model.id : null}
            onChange={handleChange("model")}
            disabled={!filter.models.length}
            placeholder="Загвараа сонгоно уу"
            showSearch
            optionFilterProp="label"
            options={filter.models.map((model) => ({
              value: model.id,
              label: model.model,
            }))}
            dropdownMatchSelectWidth={false}
            allowClear
          />
          <Select
            value={filter.engine ? filter.engine.id : null}
            onChange={handleChange("engine")}
            disabled={!filter.engines.length}
            placeholder="Хөдөлгүүрээ сонгоно уу"
            showSearch
            optionFilterProp="label"
            options={filter.engines.map((engine) => ({
              value: engine.id,
              label: engine.engine,
            }))}
            allowClear
          />
          <Button
            icon={<SearchOutlined />}
            type="primary"
            onClick={handleSearch}
          >
            Хайх
          </Button>
        </Space>
      </PageFilter>
      <PageContent>
        <Table
          dataSource={filter?.results?.rows}
          columns={columns}
          rowKey={(record) => record.id}
          bordered
          loading={loading}
          size="middle"
        />
      </PageContent>
    </PageContainer>
  );
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;
