import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { part as partApi } from "../../apis";
import { MyTable, RowAction } from "../../components";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import usePermission from "../../hooks/usePermission";

const { Option } = Select;

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { makers } = useSelector((state) => state.general);
  const [{ markets, models, bodies, years }, setSelection] = React.useState({
    markets: [],
    models: [],
    bodies: [],
    years: [],
  });
  const { checkPermission } = usePermission();
  const { s3host, isMobile } = useSelector((state) => state.general);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const columns = useHeader({
    checkPermission,
    history,
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/product/part2/${item.id}`);
          break;
        }
        default:
      }
    },
  });

  const onChange = async (type, value) => {
    if (!value) return onClear(type);

    let change = {
      [type]: value,
    };

    switch (type) {
      case "maker_id": {
        onClear("market_id");
        break;
      }
      case "market_id": {
        setFilters((state) => ({ ...state, ...change }));

        onClear("body_id");
        break;
      }
      case "body_id": {
        setFilters((state) => ({ ...state, ...change }));
        break;
      }
      default:
    }

    let res = await partApi.query(type, value, { maker_id: filters.maker_id });

    setSelection((state) => ({ ...state, ...res }));

    setFilters((state) => ({ ...state, ...change }));
  };

  const onClear = (type) => {
    let change = {
      [type]: null,
    };

    switch (type) {
      case "maker_id": {
        change = {
          ...change,
          market_id: null,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "market_id": {
        change = {
          ...change,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "model_id": {
        change = {
          ...change,
          body_id: null,
          year: null,
        };
        break;
      }
      case "body_id": {
        change = {
          ...change,
          year: null,
        };

        onChange("model_id", filters.model_id);
        break;
      }
      case "year": {
        change = {
          ...change,
          body_id: null,
        };

        onChange("model_id", filters.model_d);
        break;
      }
      default:
    }

    setFilters((state) => ({ ...state, ...change }));
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({
          ...state,
          maker_id: null,
          market_id: null,
          model_id: null,
          body_id: null,
          year: null,
          query: query,
        }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Авто машины модел" />
      <Row gutter={8} style={{ marginBottom: 20 }}>
        <Col xs={24} md={24} lg={4} style={{ marginBottom: 5 }}>
          <Input
            value={query}
            placeholder="Дугаар (body number)"
            onChange={(e) => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Үйлдвэрлэгч"
            value={filters.maker_id}
            onChange={(e) => onChange("maker_id", e)}
            style={{ width: "100%" }}
            onClear={() => onClear("maker_id")}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {makers
              .sort((a, b) => a.name - b.name)
              .map((maker, index) => (
                <Option key={index} value={maker.id}>
                  {maker.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Маркет"
            value={filters.market_id}
            onChange={(e) => onChange("market_id", e)}
            style={{ width: "100%" }}
            disabled={markets.length === 0}
            onClear={() => onClear("market_id")}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {markets
              .sort((a, b) => a.name - b.name)
              .map((market, index) => (
                <Option key={index} value={market.id}>
                  {market.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Модел"
            value={filters.model_id}
            onChange={(e) => onChange("model_id", e)}
            style={{ width: "100%" }}
            disabled={models.length === 0}
            onClear={() => onClear("model_id")}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {models
              .sort((a, b) => a.code - b.code)
              .map((model, index) => (
                <Option key={index} value={model.id}>
                  {model.code}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Араам (body)"
            value={filters.body_id}
            onChange={(e) => onChange("body_id", e)}
            style={{ width: "100%" }}
            disabled={bodies.length === 0}
            onClear={() => onClear("body_id")}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {bodies
              .filter((b) => {
                if (!filters.year) return true;

                let start = parseInt(b?.start_date?.split(".")[0], 10);
                let end = parseInt(
                  b?.end_date?.split(".")[0] || `${new Date().getFullYear()}`,
                  10
                );
                let year = parseInt(filters.year, 10);

                if (start <= year && year <= end) return true;

                return false;
              })
              ?.sort((a, b) => a.code - b.code)
              .map((body, index) => (
                <Option key={index} value={body.id}>
                  {body.code}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
          <Select
            placeholder="Огноо"
            value={filters.year}
            onChange={(e) => onChange("year", e)}
            style={{ width: "100%" }}
            disabled={years.length === 0}
            onClear={() => onClear("year")}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {years
              ?.sort((a, b) => a - b)
              .map((value, index) => (
                <Option key={index} value={value}>
                  {value}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={partApi.list}
          rowKey={(record) => record.id}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ history, onClick }) => {
  return [
    {
      title: "№",
      width: 10,
      render: (record) => {
        return record.rowIndex;
      },
    },
    {
      title: "Үйлдвэрлэгч",
      render: (record) => {
        return record?.maker?.name || "-";
      },
    },
    {
      title: "Маркет",
      render: (record) => {
        return record?.market?.name || "-";
      },
    },
    {
      title: "Модел",
      render: (record) => {
        return record?.model?.name || "-";
      },
    },
    {
      title: "Код (body)",
      render: (record) => {
        return (
          <a
            onClick={() => history.push(`/product/part2/${record.id}`)}
            href="#"
          >
            {record.code}
          </a>
        );
      },
    },
    {
      title: "Үйлдвэрлэсэн огноо",
      render: (record) => {
        return `${record.start_date} - ${record.end_date}`;
      },
    },
    {
      title: "Үйлдэл",
      width: 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              edit: "Дэлгэрэнгүй",
            }}
            onClick={(key) => onClick(key, record)}
          />
        );
      },
    },
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;
