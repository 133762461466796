import React from "react";
import styled from "styled-components";
import { ProductSwiper, ProductDetail } from "../../components";
import useFetch from "../../hooks/useFetch";
import { useParams, useHistory } from "react-router-dom";
import { product } from "../../apis";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "antd";

export default (props) => {
  const params = useParams();
  const history = useHistory();
  const { isMobile } = useSelector(state => state.general);
  if (!props._id && !params.id) return (<></>);

  const [query, setQuery] = React.useState({ _id: props._id || params.id });
  const [result, loading] = useFetch(product.get, query)({ similars: [] });

  React.useEffect(() => {
    return history.listen((history) => {
      if (params.id) {
        const { pathname } = history;

        setQuery({
          _id: pathname.split("/")[2]
        });

        window.scrollTo({
          top     : 0,
          behavior: "smooth"
        });
      }
    });
  }, [params]);

  return (
    <Product>
      <div className="emall-card">
        <Row gutter={30}>
          <Col span={12}>
            {/* <ProductSwiper images={!loading ? result.images : []} /> */}
          </Col>
          <Col span={12}>
            <ProductDetail item={!loading ? result : {}} />
          </Col>
        </Row>
      </div>
      <div className="emall-card" style={{ marginTop: 20 }}>
        <Row>
          <Col span={12}>
            {!loading ? (
              <p style={{ padding: 15 }} dangerouslySetInnerHTML={{ __html: result.body }} />
            ) : (
              <div style={{ padding: 15 }}>
                <Skeleton height={20} width="70%" style={{ marginBottom: 5 }} />
                <Skeleton height={20} width="80%" style={{ marginBottom: 5 }} />
                <Skeleton height={20} width="60%" style={{ marginBottom: 5 }} />
                <Skeleton height={20} width="50%" style={{ marginBottom: 5 }} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Product>
  );
};

const Product = styled.div`
  padding: 15px;
`;