import { Button, Col, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { kn_part as knPartApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import { tugrug } from "../../utils";

export default () => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterState, setFilterState] = useState({
    maker: "",
    model: "",
  });
  const { s3host } = useSelector((state) => state.general);
  const { product } = detail;
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await knPartApi.get(id);
        setDetail(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await knPartApi.vehicles(id);
        setVehicleList(response);
        setFilteredVehicles(response);
      } catch (err) {
        console.log("Error:::>", err);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterState((prev) => ({ ...prev, [name]: value }));
  };

  const filteredData = vehicleList.filter(
    (vehicle) =>
      vehicle.maker.toLowerCase().includes(filterState.maker.toLowerCase()) &&
      vehicle.model.toLowerCase().includes(filterState.model.toLowerCase())
  );

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 70,
      align: "center",
      render: (_, __, index) => index + 1,
    },
    {
      title: (
        <Input
          placeholder="Үйлдвэрлэгч"
          name="maker"
          value={filterState.maker}
          onChange={handleFilterChange}
          style={{ width: "100%" }}
        />
      ),
      dataIndex: "maker",
      key: "maker",
    },
    {
      title: (
        <Input
          placeholder="Загвар"
          name="model"
          value={filterState.model}
          onChange={handleFilterChange}
          style={{ width: "100%" }}
        />
      ),
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Хөдөлгүүр",
      dataIndex: "engine",
      key: "engine",
    },
    {
      title: "Үйлдвэрлэсэн он",
      dataIndex: "year",
      key: "year",
      render: (_, record) => `${record.min_year} - ${record.max_year}`,
    },
  ];

  return (
    <PageContainer>
      <PageHeader
        title="Бүтээгдэхүүний дэлгэрэнгүй"
        extra={[
          <Button
            type="primary"
            onClick={() => history.push(`/product/item/edit/${product?.id}`)}
          >
            Засах
          </Button>,
        ]}
      />
      <PageContent>
        <Row gutter={[40, 40]} style={{ marginBottom: 20 }}>
          <Col xs={24} sm={12}>
            <div>
              <img
                src={`${s3host}${product?.image}`}
                style={{ height: 300, width: "100%", objectFit: "contain" }}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              {[
                { label: "Үнэ:", value: tugrug(product?.price) },
                { label: "Код:", value: product?.code },
                { label: "Баркод:", value: product?.barcode },
                { label: "Бренд:", value: product?.brand },
                { label: "Нэр:", value: product?.name },
                { label: "Тайлбар:", value: product?.description },
              ].map((item, index, array) => (
                <React.Fragment key={index}>
                  <span
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff",
                      padding: "8px",
                      borderBottom:
                        index < array.length - 1 ? "1px solid #ccc" : "none",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    {item.label}
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff",
                      padding: "8px",
                      borderBottom:
                        index < array.length - 1 ? "1px solid #ccc" : "none",
                    }}
                  >
                    {item.value}
                  </span>
                </React.Fragment>
              ))}
            </div>
          </Col>
        </Row>
        {vehicleList.length > 0 ? (
          <>
            <h3>Таарах боломжтой машинууд:</h3>
            <Table
              dataSource={filteredData.map((vehicle, index) => ({
                ...vehicle,
                key: vehicle.id,
                index,
              }))}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: 10,
                total: filteredData.length,
                onChange: handlePageChange,
              }}
            />
          </>
        ) : (
          <p className="text-muted">Таарах машин олдсонгүй</p>
        )}
      </PageContent>
    </PageContainer>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
`;
