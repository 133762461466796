import { Table } from "antd";
import React from "react";
import styled from "styled-components";

export default React.forwardRef(
  (
    {
      loadData,
      columns,
      pagination = true,
      limit: initialLimit = 20,
      filters,
      order,
      items: rows,
      onTableChange = () => {},
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [field, setField] = React.useState();
    const [sort, setSort] = React.useState();
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(initialLimit);

    const onChange = (pagination, filterData, sorter) => {
      setPage(pagination.current);
      setLimit(pagination.pageSize);

      onTableChange(filterData);

      if (sorter && Object.keys(sorter).length && sorter.order) {
        setField(sorter.columnKey);
        setSort(sorter.order === "ascend" ? 1 : -1);
      } else {
        setField(field);
        setSort(sort);
      }
    };

    const isMounted = React.useRef(true);

    React.useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const reload = React.useCallback(
      async (signal) => {
        if (!loadData) return;
        setLoading(true);

        try {
          const res = await loadData(
            {
              filter: {
                query: "",
                ...filters,
              },
              order: {
                [field]: sort,
              },
              offset: {
                page: page,
                limit: limit,
              },
            },
            { signal }
          );

          if (isMounted.current) {
            // Only update state if component is mounted
            if (res.type === "error") throw new Error(res.payload);

            setItems(
              res.rows?.map((r, index) => ({
                ...r,
                rowKey: index + 1,
                rowIndex: (page - 1) * limit + (index + 1),
              }))
            );
            setTotal(res.count);
          }
        } catch (error) {
          console.error("Reload failed:", error);
          if (isMounted.current) {
            console.log("Алдаа гарлаа. Дахин оролдоно уу.");
          }
        } finally {
          if (isMounted.current) {
            setLoading(false);
          }
        }
      },
      [filters, limit, loadData, field, sort, page]
    );

    React.useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      reload(signal);

      return () => abortController.abort();
    }, [reload]);

    React.useImperativeHandle(ref, () => ({
      reload() {
        reload();
      },
      setItems(items) {
        setItems(items);
      },
    }));

    React.useEffect(() => {
      if (rows) {
        setItems(rows);
        setTotal(rows.length);
      }
    }, [rows]);

    return (
      <MyTable
        {...{
          scroll: { x: 100 },
          bordered: true,
          rowClassName: "row",
          className: "table",
          // size        : "small",
          columns,
          dataSource: items,
          pagination: pagination
            ? {
                className: "pagination",
                defaultCurrent: 1,
                showTitle: true,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => (
                  <span>
                    Нийт <strong>{total}</strong>-c <strong>{range[0]}</strong>-
                    <strong>{range[1]}</strong>
                    -г харуулж байна
                  </span>
                ),
                total: total,
                pageSize: limit,
                current: page,
              }
            : false,
          onChange,
          ...rest,
        }}
        loading={loading}
        rowKey={(record) => record.id ?? `record_${record.uniqueId}`}
      />
    );
  }
);

const MyTable = styled(Table)`
  .ant-table-thead > tr > th {
    white-space: nowrap !important;
    padding: 4px 8px !important;
    background: #fafafa;
    .text-center {
      text-align: center;
    }

    &:first-child {
      width: 50px;
      text-align: center;
    }
    &:last-child {
      text-align: center;
    }
  }
  .ant-table-cell {
    &:last-child {
      width: 150px;
      text-align: center;
    }
  }
  .row {
    &:hover {
      cursor: pointer;
    }
  }
`;
