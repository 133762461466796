import { Pagination, Spin } from "antd";
import React from "react";
import styled from "styled-components";

export default React.forwardRef(
  (
    {
      loadData,
      thead,
      tbody,
      tfooter,
      scroll,
      filters,
      dataSource,
      rows,
      limit: initialLimit = 20,
      onResult = () => {},
      pagination,
      className,
    },
    ref
  ) => {
    const scrollRef = React.useRef();
    const tableRef = React.useRef();
    const [scrolling, setScrolling] = React.useState();

    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState(dataSource || []);
    const [total, setTotal] = React.useState(0);

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(initialLimit);

    const onScroll = () => {
      let classLeft;
      let classRight;
      let scrollLeft = Math.abs(
        (tableRef?.current?.clientWidth || 0) -
          (scrollRef?.current?.clientWidth || 0)
      );

      if (scrollRef?.current?.scrollLeft < scrollLeft)
        classRight = "ant-table-ping-right";
      else classRight = "";

      if (scrollRef?.current?.scrollLeft > 0) classLeft = "ant-table-ping-left";
      else classLeft = "";

      setScrolling(classLeft + " " + classRight);
    };

    const changePage = (number) => {
      setPage(number);
    };

    const onShowSizeChange = (current, pageSize) => {
      setPage(current);
      setLimit(pageSize);
    };

    React.useImperativeHandle(ref, () => ({
      reload() {
        reload();
      },
      setItems(items) {
        setItems(items);
      },
    }));

    const reload = React.useCallback(
      async (signal) => {
        setLoading(true);

        try {
          const res = await loadData(
            {
              filter: filters || {
                query: "",
              },
              offset: {
                page: page,
                limit: limit,
              },
            },
            { signal }
          );

          setItems(
            res.rows?.map((r, index) => ({
              ...r,
              rowKey: index + 1,
              rowIndex: (page - 1) * limit + (index + 1),
            }))
          );
          setTotal(res.count);
          onResult(res);
          onScroll();
        } catch (err) {
          console.log(err);
        }

        setLoading(false);
      },
      [limit, page, filters]
    );

    React.useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      reload(signal);
      onScroll();
      return () => abortController.abort();
    }, [reload]);

    React.useEffect(() => {
      if (rows) {
        setItems(rows);
        setTotal(rows.length);
      }
    }, [rows]);

    return (
      <Container className={`ant-table-wrapper ${className}`}>
        <Spin spinning={loading}>
          <div
            className={`ant-table ant-table-bordered ${scrolling} ant-table-scroll-horizontal`}
          >
            <div className="ant-table-container">
              <div
                ref={scrollRef}
                onScroll={onScroll}
                className="ant-table-content"
                style={{ overflow: "auto hidden" }}
              >
                <table
                  ref={tableRef}
                  style={
                    scroll || {
                      width: "1000px",
                      minWidth: "100%",
                      tableLayout: "auto",
                    }
                  }
                >
                  {thead({ className: "ant-table-thead" })}
                  {items?.map((row, index) => {
                    return tbody(row, index);
                  })}
                </table>
              </div>
            </div>
          </div>

          {pagination !== false && (
            <Pagination
              {...{
                className: "ant-table-pagination ant-table-pagination-right",
                defaultCurrent: 1,
                showTitle: true,
                showSizeChanger: true,
                onShowSizeChange: (current, pageSize) => {
                  onShowSizeChange(current, pageSize);
                },
                onChange: (pageNumber) => {
                  changePage(pageNumber);
                },
                total: total,
                pageSize: limit,
                current: page,
              }}
            />
          )}
        </Spin>
      </Container>
    );
  }
);

const Container = styled.div``;
