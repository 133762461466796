import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { auth, general } from "./apis";
import {
  Header,
  NavBar,
  PrivateRoute,
  ProductModal,
  PublicRoute,
} from "./components";
import LoadPage from "./components/LoadPage";
import { Login, Notfound } from "./pages";
import { ArchiveList, ArchiveProduct, BoxSwap } from "./pages/Archive";
import Calculation, {
  CalculationDetail,
  CalculationInit,
  CalculationReport,
  PurchaseReport,
  RefundDetail,
  ReportLists,
} from "./pages/Calculation";
import CategoryList from "./pages/Category";
import Counting, {
  CountingDetail,
  CountingInit,
  CountingReport,
} from "./pages/Counting";
import { CustomerList } from "./pages/Customer";
import {
  DamageCart,
  DamageDetail,
  DamageEdit,
  DamageList,
} from "./pages/Damage";
import Dashboard from "./pages/Dashboard";
import { DebtDetail, DebtList } from "./pages/Debt";
import Income, { IncomeDetail, IncomeList } from "./pages/Income";
import Inout, { InoutDetail, InoutList } from "./pages/Inout";
import { PartList as KnPartList, PartDetail } from "./pages/KnPart";
import { EditOrder, OrderList } from "./pages/Order";
import { EditOrderSupplier, OrderSupplierList } from "./pages/OrderSupplier";
import { PartEdit, PartList } from "./pages/Part";
import {
  Detail as DetailPos,
  Edit as EditPos,
  PosPart,
  PosProduct,
} from "./pages/Pos";
import { ProductEdit, ProductList, ProductNew } from "./pages/Product";
import { SalesCalculation, SalesList } from "./pages/Sales";
import { EditShipment, NewShipment, ShipmentList } from "./pages/Shipment";
import { SupplierList } from "./pages/Supplier";
import { UserList } from "./pages/User";

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (token) asyncFn();
    else setLoading(false);
  }, [dispatch, token]);

  React.useEffect(() => {
    dispatch({
      type: "layout.mobile",
      payload: isMobile,
    });
  }, [isMobile]);

  if (loading) return <LoadPage />;

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/">
          <Header isMobile={isMobile} />
          <Container isMobile={isMobile}>
            <div className="content">
              <div className="navbar">
                <NavBar isMobile={isMobile} />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard} exact />

                  <Route path="/customer/list" component={CustomerList} exact />
                  <Route path="/order/list" component={OrderList} exact />
                  <Route path="/order/edit/:id" component={EditOrder} exact />

                  <Route
                    path="/order/supplier"
                    component={OrderSupplierList}
                    exact
                  />
                  <Route
                    path="/order/supplier/:id"
                    component={EditOrderSupplier}
                    exact
                  />
                  <Route
                    path="/order/:id/shipment"
                    component={NewShipment}
                    exact
                  />

                  <Route
                    path="/order/shipment"
                    component={ShipmentList}
                    exact
                  />
                  <Route
                    path="/order/shipment/:id"
                    component={NewShipment}
                    exact
                  />
                  <Route
                    path="/order/shipment/edit/:id"
                    component={EditShipment}
                    exact
                  />

                  <Route
                    path="/pos/product/:archive_id"
                    component={PosProduct}
                    exact
                  />
                  <Route
                    path="/pos/part/:archive_id"
                    component={PosPart}
                    exact
                  />
                  <Route path="/pos/sales" component={SalesList} exact />
                  <Route
                    path="/pos/sales/calculation/:id"
                    component={SalesCalculation}
                    exact
                  />
                  <Route path="/pos/detail/:id" component={DetailPos} exact />
                  <Route path="/pos/edit/:id" component={EditPos} exact />

                  <Route
                    path="/calculation/init"
                    component={CalculationInit}
                    exact
                  />
                  <Route
                    path="/calculation/:id"
                    component={Calculation}
                    exact
                  />
                  <Route
                    path="/calculation/edit/:calculation_id"
                    component={Calculation}
                    exact
                  />
                  <Route
                    path="/calculation/refund/:id"
                    component={RefundDetail}
                    exact
                  />

                  <Route
                    path="/finance/report"
                    component={CalculationReport}
                    exact
                  />
                  <Route
                    path="/finance/purchase"
                    component={PurchaseReport}
                    exact
                  />
                  <Route
                    path="/finance/report/:id"
                    component={CalculationDetail}
                    exact
                  />
                  <Route
                    path="/finance/report/edit/:id"
                    component={ReportLists}
                    exact
                  />

                  <Route path="/finance/debt" component={DebtList} exact />
                  <Route
                    path="/finance/debt/:id"
                    component={DebtDetail}
                    exact
                  />

                  <Route path="/archive/list" component={ArchiveList} exact />
                  <Route
                    path="/archive/inout/list"
                    component={InoutList}
                    exact
                  />
                  <Route
                    path="/archive/inout/detail/:id"
                    component={InoutDetail}
                    exact
                  />
                  <Route
                    path="/archive/inout/:type/:archive_id"
                    component={Inout}
                    exact
                  />
                  <Route
                    path="/archive/product"
                    component={ArchiveProduct}
                    exact
                  />
                  <Route
                    path="/archive/counting"
                    component={CountingInit}
                    exact
                  />
                  <Route
                    path="/archive/counting/:id"
                    component={Counting}
                    exact
                  />
                  <Route
                    path="/archive/report"
                    component={CountingReport}
                    exact
                  />
                  <Route
                    path="/archive/report/:id"
                    component={CountingDetail}
                    exact
                  />
                  <Route
                    path="/archive/box/:archive_id"
                    component={BoxSwap}
                    exact
                  />

                  <Route path="/product/item" component={ProductList} exact />
                  <Route
                    path="/product/item/new"
                    component={ProductNew}
                    exact
                  />
                  <Route
                    path="/product/item/edit/:id"
                    component={ProductEdit}
                    exact
                  />

                  <Route path="/product/income" component={IncomeList} exact />
                  <Route path="/product/income/new" component={Income} exact />
                  <Route
                    path="/product/income/detail/:id"
                    component={IncomeDetail}
                    exact
                  />

                  <Route path="/product/part2" component={PartList} exact />
                  <Route path="/product/part2/:id" component={PartEdit} exact />

                  <Route path="/product/kn/list" component={KnPartList} exact />
                  <Route path="/product/kn/:id" component={PartDetail} exact />

                  <Route
                    path="/settings/category"
                    component={CategoryList}
                    exact
                  />
                  <Route
                    path="/settings/supplier"
                    component={SupplierList}
                    exact
                  />
                  <Route path="/settings/user" component={UserList} exact />

                  <Route path="/pos/damage/:id" component={DamageCart} exact />
                  <Route path="/damage/list" component={DamageList} exact />
                  <Route
                    path="/damage/detail/:id"
                    component={DamageDetail}
                    exact
                  />
                  <Route path="/damage/edit/:id" component={DamageEdit} exact />

                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
          </Container>
        </PrivateRoute>
      </Switch>
      <ProductModal />
    </div>
  );
};

export default App;

const Container = styled.div`
  overflow: hidden;
  .content {
    position: relative;
    width: 100%;
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => (props.isMobile ? 80 : 230)}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden;
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
    }
    .wrapper {
      position: relative;
      padding-left: ${(props) => (props.isMobile ? 80 : 230)}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
`;
