import { Spin } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { part as partApi } from "../../apis";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await partApi.get(params.id, { signal });

      setAction(["update", res]);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={!action[1]}>{action[1] && <Form data={action[1]} />}</Spin>
  );
};
