import { Form as AntForm, Input as AntInput, Button, Switch } from "antd";
import { Formik, useFormikContext } from "formik";
import {
  Checkbox,
  DatePicker,
  Form,
  FormItem,
  Input,
  Select,
} from "formik-antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { formItemLayout, tailFormItemLayout, tugrug } from "../../../utils";

const { Option } = Select;
const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setFieldError, setSubmitting } =
    formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0) return false;

      return { values, setFieldError, setSubmitting };
    },
  }));

  return null;
});

const FormSchema = () =>
  Yup.object().shape({
    is_cash: Yup.boolean().required("Заавал бөглөх!"),
    cash_amount: Yup.number().when("is_cash", {
      is: true,
      then: Yup.number().required("Заавал бөглөх!"),
    }),
    is_card: Yup.boolean().required("Заавал бөглөх!"),
    card_amount: Yup.number().when("is_card", {
      is: true,
      then: Yup.number().required("Заавал бөглөх!"),
    }),
    is_account: Yup.boolean().required("Заавал бөглөх!"),
    account_amount: Yup.number().when("is_account", {
      is: true,
      then: Yup.number().required("Заавал бөглөх!"),
    }),
    discount_amount: Yup.number().optional().nullable(),
    surcharge_amount: Yup.number().optional().nullable(),
    customer_id: Yup.string().optional().nullable(),
    note: Yup.string().optional().nullable(),
  });

export default ({
  action,
  onSubmit,
  onCancel,
  onDestroy = () => {},
  type: ORDER_TYPE,
}) => {
  const { carts, is_sale_price, orders } = useSelector(
    (state) => state.checkout
  );
  const formHandler = useSelector((state) => state.formState);

  const { customers } = useSelector((state) => state.general);
  const submitRef = React.useRef();
  const dispatch = useDispatch();
  const [data] = React.useState({
    is_cash: false,
    is_account: false,
    is_card: false,
    cash_amount: undefined,
    account_amount: undefined,
    card_amount: undefined,
    coupon_code: undefined,
    discount_amount: undefined,
    surcharge_amount: undefined,
    customer_id: undefined,
    note: undefined,
    // order_date      : moment(new Date()),
    order_date: new Date(),
    ...(action && action[0] === "checkout" ? action[1] : {}),
  });

  const totalAmount = () => {
    return (ORDER_TYPE === "ORDER" ? orders : carts).reduce(
      (accumulator, iterator) => {
        return (
          accumulator +
          iterator.qty * (is_sale_price ? iterator.sale_price : iterator.price)
        );
      },
      0
    );
  };

  const quantities = () => {
    return (ORDER_TYPE === "ORDER" ? orders : carts).reduce(
      (accumulator, iterator) => {
        return accumulator + iterator.qty;
      },
      0
    );
  };

  const onSalePrice = () => {
    dispatch({
      type: "cart.sale_price",
    });
  };

  const onSave = async (status) => {
    const valid = await submitRef.current.submitForm();
    const { values: data, setFieldError, setSubmitting } = valid;

    if (!valid) return setSubmitting(false);

    if (ORDER_TYPE === "PURCHASE") {
      let amount = 0,
        total = totalAmount();

      total += data.surcharge_amount || 0;
      total -= data.discount_amount || 0;

      if (data.is_card === true) amount += data.card_amount;

      if (data.is_cash === true) amount += data.cash_amount;

      if (data.is_account === true) amount += data.account_amount;

      if (amount !== total) {
        if (data.is_card === true)
          setFieldError("card_amount", "Үнийн дүн зөрүүтэй!");

        if (data.is_cash === true)
          setFieldError("cash_amount", "Үнийн дүн зөрүүтэй!");

        if (data.is_account === true)
          setFieldError("account_amount", "Үнийн дүн зөрүүтэй!");

        if (
          data.is_card === false &&
          data.is_cash === false &&
          data.is_account === false
        ) {
          setFieldError("card_amount", "Төлсөн дүн!");
          setFieldError("cash_amount", "Төлсөн дүн!");
          setFieldError("account_amount", "Төлсөн дүн!");
        }

        return setSubmitting(false);
      }
    }

    try {
      await onSubmit({
        ...data,
        order_date: data.order_date,
        order_status: status,
      });
    } catch (err) {
      setSubmitting(false);
    }
  };

  // React.useEffect(() => {
  //   console.log("======= form state ========");
  //   console.log(formHandler);
  // }, [formHandler]);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={() => {}}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => {
        let TOTAL_AMOUNT = totalAmount();

        TOTAL_AMOUNT += values.surcharge_amount || 0;
        TOTAL_AMOUNT -= values.discount_amount || 0;

        const onChange = (type, field) => {
          if (!values[type]) {
            setFieldValue(field, TOTAL_AMOUNT);
          } else {
            setFieldValue(field, undefined);
          }
        };

        const onChangeValue = (field, value) => {
          switch (field) {
            case "card_amount": {
              if (values.is_cash === true)
                setFieldValue("cash_amount", TOTAL_AMOUNT - value);
              else setFieldValue("cash_amount", undefined);

              setFieldValue("account_amount", undefined);
              break;
            }
            case "cash_amount": {
              if (values.is_account === true)
                setFieldValue(
                  "account_amount",
                  TOTAL_AMOUNT -
                    (values.is_card ? values.card_amount : 0) -
                    value
                );
              else setFieldValue("account_amount", undefined);
              break;
            }
            default:
          }
        };

        return (
          <Form {...formItemLayout}>
            {ORDER_TYPE === "PURCHASE" && (
              <SwitchContainer>
                <Switch
                  checked={is_sale_price}
                  onChange={() => onSalePrice()}
                  style={{ marginRight: 10 }}
                />{" "}
                Бөөний үнээр тооцох
              </SwitchContainer>
            )}

            <Total>
              <AntForm.Item label="Тоо хэмжээ" required>
                <AntInput
                  placeholder="Тоо хэмжээ"
                  value={quantities()}
                  disabled
                />
              </AntForm.Item>
              <AntForm.Item label="Нийт дүн" required>
                <AntInput
                  placeholder="Нийт дүн"
                  value={tugrug(TOTAL_AMOUNT)}
                  disabled
                />
              </AntForm.Item>
              {ORDER_TYPE === "PURCHASE" && (
                <>
                  <FormItem label="Хямдарсан дүн" name="discount_amount">
                    <Input
                      type="number"
                      name="discount_amount"
                      placeholder="Хямдарсан дүн (discount)"
                    />
                  </FormItem>
                  <FormItem label="Нэмэлт төлбөр" name="surcharge_amount">
                    <Input
                      type="number"
                      name="surcharge_amount"
                      placeholder="Нэмэлт төлбөр"
                    />
                  </FormItem>
                </>
              )}
            </Total>
            {ORDER_TYPE === "PURCHASE" && (
              <>
                <FormItemGroup label="Картаар" name="card_amount" required>
                  <div className="group">
                    <Input
                      min={0}
                      type="number"
                      name="card_amount"
                      placeholder="Картаар"
                      disabled={!values.is_card}
                      onChange={(e) =>
                        onChangeValue("card_amount", e.target.value)
                      }
                    />
                    <div className="exclusion">
                      <Checkbox
                        name="is_card"
                        onChange={() => onChange("is_card", "card_amount")}
                      />
                    </div>
                  </div>
                </FormItemGroup>
                <FormItemGroup label="Бэлнээр" name="cash_amount" required>
                  <div className="group">
                    <Input
                      min={0}
                      type="number"
                      name="cash_amount"
                      placeholder="Бэлнээр"
                      disabled={!values.is_cash}
                      onChange={(e) =>
                        onChangeValue("cash_amount", e.target.value)
                      }
                    />
                    <div className="exclusion">
                      <Checkbox
                        name="is_cash"
                        onChange={() => onChange("is_cash", "cash_amount")}
                      />
                    </div>
                  </div>
                </FormItemGroup>
                <FormItemGroup label="Дансаар" name="account_amount" required>
                  <div className="group">
                    <Input
                      min={0}
                      type="number"
                      name="account_amount"
                      placeholder="Дансаар"
                      disabled={!values.is_account}
                      onChange={(e) =>
                        onChangeValue("account_amount", e.target.value)
                      }
                    />
                    <div className="exclusion">
                      <Checkbox
                        name="is_account"
                        onChange={() =>
                          onChange("is_account", "account_amount")
                        }
                      />
                    </div>
                  </div>
                </FormItemGroup>
              </>
            )}
            <FormItem name="customer_id" label="Харилцагч">
              <Select
                name="customer_id"
                placeholder="Харилцагч"
                allowClear
                showSearch
              >
                {customers.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.type === "ORGANIZATION"
                      ? `${item.name}`
                      : `${item?.last_name} ${item?.first_name}`}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="order_date" label="Борлуулсан огноо">
              <DatePicker
                name="order_date"
                style={{ width: "100%" }}
                placeholder="Огноо"
                allowClear
              />
            </FormItem>
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="note"
              label="Нэмэлт мэдээлэл (Тайлбар)"
            >
              <TextArea type="text" name="note" placeholder="Нэмэлт мэдээлэл" />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button
                className="button"
                style={{ marginRight: 10 }}
                type="default"
                onClick={onCancel}
              >
                Болих
              </Button>
              {/* {ORDER_TYPE === "PURCHASE" && !data.id && <Button className="button" style={{ marginRight: 10 }} type="default" loading={isSubmitting} onClick={() => onSave("DRAFT")}>Хадгалах</Button>} */}
              {ORDER_TYPE === "PURCHASE" && data.id && (
                <Button
                  className="button"
                  style={{ marginRight: 10 }}
                  type="danger"
                  loading={isSubmitting}
                  onClick={() => onDestroy()}
                >
                  Цуцлах
                </Button>
              )}
              <Button
                className="button"
                type="primary"
                loading={isSubmitting}
                onClick={() => onSave("COMPLETED")}
              >
                Хадгалах
              </Button>
            </AntForm.Item>

            <SubmitForm ref={submitRef} />
          </Form>
        );
      }}
    </Formik>
  );
};
const SwitchContainer = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: whitesmoke;
`;
const Total = styled.div`
  .ant-input-disabled {
    background: #fff;
    color: #333;
  }
`;
const FormItemGroup = styled(FormItem)`
  position: ralative;
  .range-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    .range-to {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 74px;
    }
  }
  .ant-picker,
  ant-picker-range {
    width: 100%;
  }
  .group {
    display: flex;
    flex-direction: row;
    .exclusion {
      height: 32px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .has-exclusion {
    position: absolute;
    right: 0;
  }
`;
