import React from "react";
import { Button } from "antd";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";

const CategoryList = props => {
  const { checkPermission } = usePermission();
  const { onNew, onSelect, onEdit, onRemove, items, selected, index, ...rest } = props;

  return (
    <Container>
      {checkPermission("CATEGORY", { write: true }) && (
        <div className={"action"}>
          <Button onClick={onNew}>
          Нэмэх
          </Button>
        </div>
      )}
      <div className={"stylizedList"}>
        {items&&items.map((item, index) => {
          return (
            <SortableItem
              key={index}
              index={index}
              onSelect={item => {
                onSelect(item);
              }}
              onEdit={() => onEdit(item)}
              onRemove={() => onRemove(item)}
              item={item}
              isActive={selected && selected.id === item.id}
              {...rest}
            />
          );
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
.action {
  margin-bottom: 16px;
}

// Stylized
.stylizedList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  overflow: auto;
  height: 600px;
}

`;

export default SortableContainer(CategoryList);
