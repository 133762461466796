const initialState = {
  filters: {
    query: "",
    year: null,
    maker: null,
    makers: [],
    model: null,
    models: [],
    engine: null,
    engines: [],
  },
  results: null,
};

export const SET_FILTERS = "SET_FILTERS";
export const SET_RESULTS = "SET_RESULTS";

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const setResults = (results) => ({
  type: SET_RESULTS,
  payload: results,
});

const search = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
          makers: Array.isArray(action.payload.makers)
            ? action.payload.makers
            : state.filters.makers,
          models: Array.isArray(action.payload.models)
            ? action.payload.models
            : state.filters.models,
          engines: Array.isArray(action.payload.engines)
            ? action.payload.engines
            : state.filters.engines,
        },
      };
    case SET_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    default:
      return state;
  }
};

export default search;
