import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import dragImage from "./drag-reorder.png";

const DragHandle = () => (
  <Container >
    <img src={dragImage} width='20' alt='' />
  </Container>
);

const Container = styled.div `
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: row-resize;

`;

export default SortableHandle(DragHandle);
