import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import NoImage from "../../assets/noimage.png";
import styled from "styled-components";

const { Header } = Layout;

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { isMobile } = useSelector(state => state.general);

  const logout = () => {
    dispatch({
      type: "auth.logout",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <UserOutlined /> Миний мэдээлэл
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <LogoutOutlined /> Гарах
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderStyled className={"header"}>
      <div className={"navbar"}>
        <div className={"logo"} >
          <LogoSVG />
        </div>
      </div>
      {isMobile ? (<div className={"userWrap"}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className={"userAction"}>
            <UserOutlined/>
          </div>
        </Dropdown>
      </div>) : (<div className={"userInfo"}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className={"userAction"}>
            <div className={"avatar"}>
              <img src={NoImage} alt="Avatar" />
            </div>
            <div className={"username"}>
              {`${user?.last_name} ${user?.first_name}`}
              <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>)}
    </HeaderStyled>
  );
};

const HeaderStyled = styled(Header)`
&.header {
  border-bottom: 1px solid #ededed;
  box-shadow: 0 2px 8px #f0f1f2;
  flex-direction: row;
  height: 60px;
  background: #fff;

  .logo {
    svg {
      width: 200px;
    }
  }

  .navbar {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    align-items: center;
  }
  .userWrap {
    max-width: 60px;
    min-width: 60px;
    height: 60px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-bottom: 1px solid #ededed;
    transition: .3s;
    justify-content: center;
    align-items: center;
    .userAction {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;
      width: 30px; 
      height: 30px; 
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ededed;
    }
  }
  .userInfo {
    max-width: 400px;
    min-width: 250px;
    height: 59px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-left: 1px solid #ededed;
    transition: .3s;

    .userAction {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;
      
      .avatar {
        img {
          position: relative;
          overflow: hidden;
          margin-top: 5px;
          margin-left: 10px;
          height: 50px;
          width: 50px;
          border-radius: 60px;
          border: 1px solid #ededed;
          display: flex;
        }
      }
      .username {
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-left: 10px;
        padding-right: 35px;

        :global {
          .anticon-down {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            display: flex!important;
            align-items: center;
          }
        }
      }
    }
  }
}
`;