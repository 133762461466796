import { ToolFilled } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { MyTable } from "../../components";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";

export default ({ data }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const [position, setPosition] = React.useState(
    data?.body_positions ? data?.body_positions[0] : {}
  );
  const { s3host } = useSelector((state) => state.general);

  const columns = useHeader({
    s3host,
    history,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/part/edit/${item.id}`);
          break;
        }
        default:
      }
    },
  });

  return (
    <PageContainer>
      <PageHeader
        title={`${data?.maker?.name} ${data?.model?.name}`}
        subTitle={data?.market?.name}
      />
      <PageContent>
        <Container>
          <Row gutter={8}>
            <Col className="left schema" xl={6}>
              <label>Дугаар (body number):</label>
              <h2>{data?.code}</h2>
              <label>Үйлдвэрлэсэн огноо:</label>
              <h2>
                {data?.start_date}-{data?.end_date}
              </h2>

              <div className="image">
                <img src={`${s3host}/${position.image}`} width="100%" />
              </div>
            </Col>
            <Col className="main" xl={18}>
              <ul className="tabs">
                {data?.body_positions?.map((pos, index) => {
                  return (
                    <li
                      id={pos.code}
                      key={index}
                      className={`${position?.id === pos.id && "active"}`}
                    >
                      <a
                        className="tab"
                        href="#"
                        onClick={() => setPosition(pos)}
                      >
                        <img src={`${s3host}/${pos.icon}`} alt="" />
                        {pos.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <MyTable
                columns={columns}
                ref={myTableRef}
                items={data?.parts
                  ?.filter((part) => part.body_position_id === position.id)
                  ?.sort(
                    (a, b) =>
                      (a?.sort_of_position ?? 0) - (b?.sort_of_position ?? 0)
                  )}
                rowKey={(record) => record.id}
              />
            </Col>
          </Row>
        </Container>
      </PageContent>
    </PageContainer>
  );
};
const useHeader = ({ s3host }) => {
  return [
    {
      title: "On scheme",
      width: 100,
      render: (record) => {
        if (!record.part) return record.sort_of_position;

        return (
          <>
            {record.sort_of_position}{" "}
            <Avatar
              src={record?.image || `${s3host}/${record?.part?.image}`}
              size={100}
              shape="square"
            />
          </>
        );
      },
    },
    {
      title: "PART / Мэдээлэл",
      render: (record) => {
        if (!record?.part)
          return (
            <>
              <p style={{ margin: 0 }}>
                Not Available
                {record.additional && (
                  <div style={{ fontSize: 12 }}>{record.additional}</div>
                )}
              </p>
            </>
          );

        const onCar = (note) => {
          let array = note
            ?.split("on car:")[1]
            ?.split("&&")[0]
            ?.trim()
            ?.split("package:");

          let car = ((array && array[0]) || "")?.trim();
          let pcs = ((array && array[1]) || "")?.trim();

          return (
            <>
              <Tag style={{ marginBottom: 10 }} color="#f50">
                On car: {car}{" "}
              </Tag>
              <Tag style={{ marginBottom: 10 }} color="#2db7f5">
                Package: {pcs}{" "}
              </Tag>
            </>
          );
        };

        return (
          <>
            <Tag color="black">{record?.part?.code}</Tag>
            <p>
              {record?.part?.name}
              {record?.additional && (
                <div style={{ fontSize: 12 }}>{record?.additional}</div>
              )}
            </p>
            <div>{record.note && onCar(record.note)}</div>
          </>
        );
      },
    },
    {
      title: "Гарын авлага",
      width: 50,
      render: (record) => {
        return (
          <a href={record?.part?.manual_url} target="_blank">
            <Button className="manual" type="default" size="large">
              <ToolFilled size={44} />
            </Button>
          </a>
        );
      },
    },
  ];
};

const Container = styled.div`
  position: relative;
  background: #fff;
  .manual {
    &.ant-btn {
      height: 50px;
      font-size: 22px;
    }
  }
  .schema {
    width: 100%;
    .image {
      width: 100%;
    }
  }
  .main {
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        margin-bottom: 5px;
        &.active {
          a.tab {
            background: #f3f3f3;
            color: #feca0b;
            border-bottom: 4px solid #feca0b;
          }
        }
        a.tab {
          display: flex;
          flex-direction: column;
          padding: 10px;
          /* background: #333; */
          border: 1px solid #ddd;
          border-bottom: 4px solid #ddd;
          color: #333;
          img {
            width: 100px;
            margin: auto;
          }
          &:hover {
            background: #f3f3f3;
            color: #feca0b;
          }
        }
      }
    }
  }
`;
